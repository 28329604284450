.App {
  text-align: center;
  padding-top: 50px;
}

.App-logo{
  text-align: center !important;
  position: center;
}


.App-header {
  /* background-color: #282c34; */
  background-color: #406fef;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.lead{
  padding: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.spinnerContainer {
  padding-bottom: 50px !important;
  background-color: #FFF;
}